<script>
import Layout from "../../layouts/auth";
// import router from "@/router";
// import store from "@/state/store";
// var state = store.state;

import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import { mapState } from "vuex";
import appConfig from "@/app.config";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      email: "",
      pass: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      email_role: [],
      loginAsAdmin: true
    };
  },
  validations: {
    email: {
      required: helpers.withMessage("emailname is required", required),
    },
    pass: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    setLoginAsAdmin(admin) {
      if (admin) this.loginAsAdmin = true;
      else this.loginAsAdmin = false;
    },
    // Try to log the email in with the emailname
    // and pass they provided.
    async tryToLogIn() {
      this.$login.loginUser(this.email, this.pass).then((res) => {
        if (res.status)
          this.$router.push("/dashboard/home");

      })
    },
    async logInAsTeamMember() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        console.log(this.user);
        try {

          this.$login.loginMarketing(this.email, this.pass).then((res) => {
            if (res.status)
              this.$router.push("/dashboard/team_member_home");

          })
          // const res = await this.http.post("users/login-marketing-team", {
          //   email: this.email,
          //   pass: this.pass,
          // });
          // if (res?.status) {

          //   console.log(120, res.data);
          //   console.log(121, res.data?.user);

          //   localStorage["currentUser"] = JSON.stringify(res.data?.user);

          //   this.$router.push("/dashboard/team_member_home");
          //   return;
          // }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  mounted() {
    console.log(this.$e.encrypt("Hello"));
  },
};
</script>

<template>
  <Layout>
    <div v-if="loginAsAdmin" class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p>Sign in to Smart POS Admin Panel.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>
            <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
            <div v-if="notification.message" :class="'alert ' + notification.type">
              {{ notification.message }}
            </div>

            <b-form class="p-2" @submit.prevent="tryToLogIn">
              <!-- <b-form-group
                class="mb-3"
                id="input-group-1"
                label="Username"
                label-for="input-1"
              > -->
              <!-- <b-form-input
                  id="input-1"
                  v-model="user"
                  type="text"
                  placeholder="Enter Username"
                  :class="{
                    'is-invalid': submitted && v$.user.$error,
                  }"
                ></b-form-input> -->
              <!-- <div
                  v-for="(item, index) in v$.user.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div> -->
              <!-- </b-form-group> -->
              <b-form-group class="mb-3" id="input-group-1" label="Email" label-for="input-1">
                <b-form-input id="input-1" v-model="email" type="text" placeholder="Enter Email" :class="{
                  'is-invalid': submitted && v$.email.$error,
                }"></b-form-input>
                <div v-for="(item, index) in v$.email.$errors" :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </b-form-group>


              <b-form-group class="mb-3" id="input-group-2" label="Password" label-for="input-2">
                <b-form-input id="input-2" v-model="pass" type="password" placeholder="Enter password" :class="{
                  'is-invalid': submitted && v$.pass.$error,
                }"></b-form-input>
                <div v-if="submitted && v$.pass.$error" class="invalid-feedback">
                  <span v-if="v$.pass.required.$message">{{
                    v$.pass.required.$message
                  }}</span>
                </div>
              </b-form-group>
              <b-form-checkbox class="form-check me-2 mt-0" id="customControlInline" name="checkbox-1" value="accepted"
                unchecked-value="not_accepted">Remember me
              </b-form-checkbox>
              <a class="mt-2 d-block" href="#" @click="setLoginAsAdmin(false)">Login as marketing team member</a>
              <div class="mt-3 d-grid">
                <b-button type="submit" variant="primary" class="btn-block">Log In</b-button>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <div v-else class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-success">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p>Sign in to Marketing Member Panel.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>
            <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
            <div v-if="notification.message" :class="'alert ' + notification.type">
              {{ notification.message }}
            </div>

            <b-form class="p-2" @submit.prevent="logInAsTeamMember">
              <b-form-group class="mb-3" id="input-group-1" label="Email" label-for="input-1">
                <b-form-input id="input-1" v-model="email" type="text" placeholder="Enter Email" :class="{
                  'is-invalid': submitted && v$.email.$error,
                }"></b-form-input>
                <div v-for="(item, index) in v$.email.$errors" :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </b-form-group>

              <b-form-group class="mb-3" id="input-group-2" label="Password" label-for="input-2">
                <b-form-input id="input-2" v-model="pass" type="password" placeholder="Enter password" :class="{
                  'is-invalid': submitted && v$.pass.$error,
                }"></b-form-input>
                <div v-if="submitted && v$.pass.$error" class="invalid-feedback">
                  <span v-if="v$.pass.required.$message">{{
                    v$.pass.required.$message
                  }}</span>
                </div>
              </b-form-group>
              <b-form-checkbox class="form-check me-2 mt-0" id="customControlInline" name="checkbox-1" value="accepted"
                unchecked-value="not_accepted">Remember me
              </b-form-checkbox>
              <a class="mt-2 d-block" href="#" @click="setLoginAsAdmin(true)">Login as admin user</a>
              <div class="mt-3 d-grid">
                <b-button type="submit" variant="primary" class="btn-block">Log In</b-button>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
